<template>
  <div>
    <vb-register />
  </div>
</template>
<script>
import VbRegister from '@/@vb/components/Auth/Register'
export default {
  components: {
    VbRegister,
  },
}
</script>
